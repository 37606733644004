<template>
    <div class="role public_bg">
        <Top  name="龙纹" email back v-if="topShow"></Top>
        <div class="public_tw">
            <div class="public_sheet">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">穿戴龙纹</div>
                        <van-skeleton title :row="3"  :loading="loading" />
                        <div class="figure_main" v-for="(item, index) in list.res_body" :key="index">
                            <div class="figure_main_top">{{item.body_name}}</div>
                            <div class="figure_main_bottom"  v-for="(items, id) in item.goods_details" :key="id">{{items.goods_name}}&ensp;Lv{{items.lv}}&ensp;{{items.attrinfo}}</div>
                        </div>
                        <!-- <div class="figure_main">
                            <div class="figure_main_top">龙纹武器</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                        </div> -->
                        <!-- <div class="figure_main">
                            <div class="figure_main_top">龙纹武器</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                        </div>
                        <div class="figure_main">
                            <div class="figure_main_top">龙纹武器</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10: 物理伤害减少0.4%</div>
                        </div>
                        <div class="figure_main">
                            <div class="figure_main_top">龙纹武器</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10</div>
                            <div class="figure_main_bottom">坚甲龙纹 Lv10</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="public_sheet">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">龙纹仓库</div>
                        <van-skeleton title :row="3" :loading="loading" />
                        <div class="figure_main">
                            <div class="figure_main_bottom" v-for="(item, index) in list.res_hub" :key="index">{{item.goods_name}}&ensp;Lv{{item.lv}}&ensp;{{item.attrinfo}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
export default {
    name:"figure",
    data(){
        return {
            params: {},
            list:[],
            loading: true,
            topShow: true
        }
    },
    components: {
      Top
    },
    created(){
        //获取路由参数
        this.params = this.$route.query;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        this.getEquipmentData();
    },
    methods:{
        //获取龙纹数据
        getEquipmentData() {
            this.$api.shopping.getLongwen(this.params).then(res => {
                if (res.code == 200) {
                    this.loading = false;
                    this.list = res.data;
                    // this.loading = false;
                    // console.log(res)
                    // this.list = res.data;
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    
}
</script>
<style scoped>
@import "../../assets/css/common.css"
</style>